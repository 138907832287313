/**
 * 404.jsx
 * 
 * @file Not found page of the website.
 * @author Robin Walter <hello@robinwalter.me>
 */

import clsx from 'clsx'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import Lottie from 'react-lottie'
import React from 'react'

// internal imports
import { Layout } from '../components/Layout'
import { NoSSR } from '../components/NoSSR'
/**!
 * Copyright © Oscar Daniel Martnez Nez 
 * @license CC BY 2.0 {@link https://creativecommons.org/licenses/by/2.0/}
 * @link https://lottiefiles.com/OscarMartinez
 * @link https://lottiefiles.com/4339-not-found?lang=en
 */
import notFoundAnimationData from '../assets/animations/404.json'

/**
 * This Component represents the not found page of this application.
 *
 * @returns {Node} The rendered page.
 */
const NotFoundPage = () => {

    const defaultOptions = {
		animationData: notFoundAnimationData,
		autoplay: true,
    	loop: false,
    	rendererSettings: {
        	preserveAspectRatio: 'xMidYMid slice'
    	}
    }

    return (
        <>
            <GatsbySeo title="404 – Seite nicht gefunden" />
            <Layout>

                <div
                    className={ clsx( 'uk-section-muted', 'uk-section', 'uk-section-large' ) }
                    id="not-found">

                    <div className={ clsx( 'uk-container' ) }>

                        <NoSSR>
                            <Lottie
                                isPaused={ false }
                                isStopped={ false }
                                options={ defaultOptions } />
                            <p className={ clsx( 'uk-text-meta', 'uk-text-center' ) } style={ { fontSize: '0.75rem' } }>Copyright © <a href="https://lottiefiles.com/OscarMartinez" rel="noopener noreferrer nofollow" target="_blank">Oscar Daniel Martnez Nez</a>. Licensed under <a href="https://lottiefiles.com/page/license" rel="noopener noreferrer nofollow" target="_blank">Creative Commons License 2.0</a>.</p>
                        </NoSSR>

                    </div>

                </div>

            </Layout>
        </>
    )

}

export default NotFoundPage
